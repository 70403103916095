
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "UserOfficesTariffSelect",
  props: {
    office_id: {
      type: [Number, Array],
      default: undefined,
    },
    data: {
      type: [Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    change: {
      type: Boolean,
      default: true,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:office_id", "changeOffice"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const current_user = computed(() => store.getters.currentUser);
    const inputElements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isOfficeRequired = (value) => {
      if (props.required && !value) {
        return t("rentity");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "location",
      isOfficeRequired
    );

    const getElements = () => {
      const temp = localStorage.getItem("user") as any;
      const user = JSON.parse(temp);
      const payload = {
        per_page: 10,
        users: [user.id],
      };
      ApiService.query("/api/offices/lists", {
        params: payload,
      }).then(({ data }) => {
        inputElements.value.list = data.offices;
        inputElements.value.options = data.offices;
      });
    };
    const selectElements = (query) => {
      if (query !== "") {
        inputElements.value.loading = true;
        setTimeout(() => {
          inputElements.value.loading = false;
          inputElements.value.options = inputElements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputElements.value.options.length) {
            const temp = localStorage.getItem("user") as any;
            const user = JSON.parse(temp);
            ApiService.query(`/api/offices/lists`, {
              params: {
                per_page: 10,
                users:
                  current_user.value.id !== undefined
                    ? [current_user.value.id]
                    : [user.id],
                name: query,
              },
            }).then(({ data }) => {
              inputElements.value.list = data.offices;
              inputElements.value.options = data.offices;
            });
          }
        }, 200);
      } else {
        inputElements.value.options = [];
      }
    };

    watch(
      () => props.office_id,
      (first) => {
        element_id.value = first;
        const selected = inputElements.value.list.find((x) => x.id === first);
        if (props.change) {
          emit("changeOffice", selected.name);
        }
        if (element_id.value.length) {
          const index = inputElements.value.list.findIndex(
            (x) => x.id === element_id.value[0]
          );
          if (index > -1) {
            const accounting_plan_id =
              inputElements.value.list[index].accounting_plan;
            const temp = localStorage.getItem("user") as any;
            const user = JSON.parse(temp);
            ApiService.query(`/api/offices/lists`, {
              params: {
                per_page: 10,
                accounting_plans: [accounting_plan_id],
                users:
                  current_user.value.id !== undefined
                    ? [current_user.value.id]
                    : [user.id],
              },
            }).then(({ data }) => {
              inputElements.value.list = data.offices;
              inputElements.value.options = data.offices;
            });
          }
        } else {
          getElements();
        }
      }
    );

    watch(
      () => props.data,
      (first) => {
        if (first.length) {
          inputElements.value.list = first;
          inputElements.value.options = first;
        }
      }
    );

    onMounted(() => {
      element_id.value = props.office_id;
      if (props.mounted) {
        getElements();
      }
    });

    return {
      element_id,
      inputElements,
      errorMessage,
      current_user,
      getElements,
      selectElements,
    };
  },
};
